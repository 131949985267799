<template>
  <div class="user_manage-content-row">
    <div class="table--col col-3">
      <p>{{ data.top_up_amount }}</p>
    </div>
    <div class="table--col col-2">
      <p>{{ data.withdrawal_amount }}</p>
    </div>
    <div class="table--col col-3">
      <p>{{ data.operation_number }}</p>
    </div>
    <div class="table--col col-2">
      <p>{{ data.status }}</p>
    </div>
    <div class="table--col col-2">
      <p>{{ data.token }}</p>
    </div>
    <div class="table--col col-2">
      <p>{{ data.date }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTransList',
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>
