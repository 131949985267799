<template>
  <div>
    <div class="user_manage" v-if="user">
      <div class="user_manage-header">
        <h2>Карточка пользователя</h2>
        <button @click="handleChangeUser" class="btn--green">
          {{ label }}
        </button>
      </div>
      <div class="user_manage-user_card">
        <div class="user_manage-user_card-first_col">
          <div class="user_manage-user_card-avatar">
            <img src="@/assets/img/avatar_user.png" alt="avatar" />
          </div>
          <div class="user_manage-user_card-text">
            <h3>{{ user.nickname ? user.nickname : 'Имя' }}</h3>
            <p class="user_manage-user_card-text_info">
              Уровень:<span>{{ user.level }}</span>
            </p>
          </div>
        </div>
        <div class="user_manage-user_card-second_col">
          <p class="user_manage-user_card-text_info">
            ID:<span>{{ user.id }}</span>
          </p>
          <p class="user_manage-user_card-text_info">
            E-mail:<span>{{ user.email }}</span>
          </p>
          <p class="user_manage-user_card-text_info">
            Статус:<span>{{ user.pin_status ? 'Онлайн' : 'Оффлайн' }}</span>
          </p>
        </div>
        <div class="user_manage-user_card-third_col">
          <p class="user_manage-user_card-text_info">
            Дата регистрации:<span>{{ formatDate(user.created_at) }}</span>
          </p>
          <p class="user_manage-user_card-text_info">
            Последняя активность:<span>{{ formatDate(user.updated_at) }}</span>
          </p>
          <button @click="openSkinModal" class="btn-white--border">
            Скины пользователя
          </button>
        </div>
      </div>
      <h4>История транзакций</h4>
      <template v-if="user.transactions.length">
        <TableHeader :data="tableHead" />
        <UserTransList
          v-for="(row, key) in user.transactions"
          :data="row"
          :key="key"
        />
      </template>
      <template v-else>
        <p class="trans--all">Транзакции отсутствуют</p>
      </template>
    </div>
    <div class="user_manage" v-else>
      <div class="user_manage-header">
        <h2>Пользователь с таким ID отсутствует</h2>
      </div>
    </div>
    <div v-if="isLoading">
      <loader
        object="#fff"
        color1="#435A64"
        color2="#ECEFF0"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </div>
  </div>
</template>

<script>
import TableHeader from '@/components/ui/TableHeader';
import UserTransList from '@/components/ui/UserTransList';
import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { loader } from 'vue-ui-preloader';

export default {
  name: 'UserManage',
  data() {
    return {
      tableHead: [
        {
          col: 3,
          label: 'Сумма пополнения',
        },
        {
          col: 2,
          label: 'Сумма снятия',
        },
        {
          col: 3,
          label: 'Номер операции',
        },
        {
          col: 2,
          label: 'Статус',
        },
        {
          col: 2,
          label: 'Токены',
        },
        {
          col: 2,
          label: 'Дата',
        },
      ],
    };
  },
  mounted() {
    this.getUser(this.$route.params.id.match(/[0-9]{1,}/)[0]);
  },
  methods: {
    ...mapMutations(['openModal', 'closeModal', 'clearStateUser']),
    ...mapActions(['getUser', 'banUser']),
    moment,
    handleChangeUser() {
      this.openModal({
        nameModal: 'ConfirmUserChange',
        dataModal: {
          label: this.label,
          nameUser: this.user.name ? this.user.name : this.user.email,
          type: !this.user.is_banned,
          callbackModal: days => {
            this.closeModal();
            this.banUser({
              user_id: this.user.id,
              days: days,
              type: !this.user.is_banned,
              successCallback: () => {
                this.getUser(this.user.id);
              },
            });
          },
        },
      });
    },
    openSkinModal() {
      this.openModal({
        nameModal: 'SkinUser',
        dataModal: {
          data: this.user.hamsterClothes,
        },
      });
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
  },
  computed: {
    ...mapState({
      user: state => state.user,
      isLoading: state => state.isLoading,
    }),
    label() {
      if (this.user?.is_banned) {
        return 'Востановить';
      }
      return 'Заглушить';
    },
  },
  beforeDestroy() {
    this.clearStateUser();
  },
  components: {
    TableHeader,
    UserTransList,
    loader,
  },
};
</script>

<style lang="scss" scoped></style>
