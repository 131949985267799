<template>
  <div class="table-header">
    <div
      v-for="(head, key) in data"
      :class="`table--col col-${head.col}`"
      :key="key"
    >
      <p>{{ head.label }}</p>
      <ArrowSortsGray />
    </div>
  </div>
</template>

<script>
import ArrowSortsGray from '@/components/ui/icons/ArrowSortsGray';

export default {
  name: 'TableHeader',
  props: {
    data: Array,
  },
  components: {
    ArrowSortsGray,
  },
};
</script>

<style scoped></style>
