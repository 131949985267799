<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_880_1311"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_880_1311)">
      <path d="M12 15L7 10H17L12 15Z" fill="#A1ADB2" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowSortsGray',
};
</script>

<style scoped></style>
